import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useDailyCountdownDateTimer = () => {
	const calculateTimeLeft = () => {
		const now: any = new Date();

		// we could also set the utc and then use it instead
		const utc: any = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
		const targetDate: any = new Date(now);

		targetDate.setHours(24, 0, 0, 0); // Set the target time to 24:00:00 (midnight)

		// let difference = targetDate - nowInUtc;
		let difference = targetDate - utc;
		// If the target time is in the past, calculate for the next day
		if (difference <= 0) {
			targetDate.setDate(targetDate.getDate() + 1);
			difference = targetDate - now;
		}

		const timeLeft = {
			hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
			minutes: Math.floor((difference / (1000 * 60)) % 60),
			seconds: Math.floor((difference / 1000) % 60),
		};

		return timeLeft;
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearInterval(timer);
	}, []);

	return {
		hoursLeft: timeLeft.hours,
		minutesLeft: timeLeft.minutes,
		secondsLeft: timeLeft.seconds,
	};
};

export const useDailyGameChipsCountdownDateTimer = () => {
	const userProfile = useSelector(
		(state: any) => state.userReducer.userProfile
	);
	console.log('userProfile', userProfile);

	const secondsRemaining = userProfile?.freeModeWalletBalanceTTl; // Get initial seconds remaining

	const [timeLeft, setTimeLeft] = useState({
		hours: Math.floor(secondsRemaining / 3600),
		minutes: Math.floor((secondsRemaining % 3600) / 60),
		seconds: secondsRemaining % 60,
	});

	console.log('timeLeft', timeLeft);

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft((prevTimeLeft) => {
				const totalSeconds =
					prevTimeLeft.hours * 3600 +
					prevTimeLeft.minutes * 60 +
					prevTimeLeft.seconds -
					1;

				if (totalSeconds <= 0) {
					clearInterval(timer);
					return { hours: 0, minutes: 0, seconds: 0 }; // Stop countdown at zero
				}

				return {
					hours: Math.floor(totalSeconds / 3600),
					minutes: Math.floor((totalSeconds % 3600) / 60),
					seconds: totalSeconds % 60,
				};
			});
		}, 1000);

		return () => clearInterval(timer);
	}, []);

	return {
		hoursLeft: timeLeft.hours,
		minutesLeft: timeLeft.minutes,
		secondsLeft: timeLeft.seconds,
	};
};

export const useWeeklyCountdownTimer = (endOfWeek: any) => {
	const calculateTimeLeft = () => {
		const now: any = new Date();
		const nowInUtc: any = new Date(
			now.getUTCFullYear(),
			now.getUTCMonth(),
			now.getUTCDate(),
			now.getUTCHours(),
			now.getUTCMinutes(),
			now.getUTCSeconds()
		);
		const fridayDate: any = new Date(endOfWeek);
		// const difference = fridayDate - now;
		const difference = fridayDate - nowInUtc;
		const timeLeft = {
			days: Math.floor(difference / (1000 * 60 * 60 * 24)),
			hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
			minutes: Math.floor((difference / (1000 * 60)) % 60),
			seconds: Math.floor((difference / 1000) % 60),
		};
		return timeLeft;
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearInterval(timer);
	}, [endOfWeek]);

	return {
		days: timeLeft.days,
		hours: timeLeft.hours,
		minutes: timeLeft.minutes,
		seconds: timeLeft.seconds,
	};
};

export const useCountdown = (expirationDate: string) => {
	const calculateTimeLeft = () => {
		const now = new Date().getTime();
		const expiration = new Date(expirationDate).getTime();
		const distance = expiration - now;

		if (distance <= 0) {
			return {
				days: 0,
				hours: 0,
				minutes: 0,
				seconds: 0,
			};
		}

		const days = Math.floor(distance / (1000 * 60 * 60 * 24));
		const hours = Math.floor(
			(distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
		);
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((distance % (1000 * 60)) / 1000);

		return { days, hours, minutes, seconds };
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearInterval(timer);
	}, [expirationDate]);

	return timeLeft;
};

export const useSecondsCountdown = (initialSeconds: any) => {
	const [secondsLeft, setSecondsLeft] = useState(Number(initialSeconds));

	useEffect(() => {
		if (secondsLeft <= 0) return; // Stop the countdown at 0

		const intervalId = setInterval(() => {
			setSecondsLeft((prevSeconds: number) => prevSeconds - 1);
		}, 1000);

		return () => clearInterval(intervalId); // Cleanup interval on unmount
	}, [secondsLeft]);

	// Function to format the time as Xd Xh Xm Xs
	const days = Math.floor(secondsLeft / (3600 * 24));
	const hours = Math.floor((secondsLeft % (3600 * 24)) / 3600);
	const minutes = Math.floor((secondsLeft % 3600) / 60);
	const secs = Math.floor(secondsLeft % 60);

	return { days, hours, minutes, secs };
};
